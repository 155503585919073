import React, { useState, useEffect } from "react";
import firebase from "../../config"
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper';
import Select, { components } from 'react-select';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AutoSelect from '../AutoSelect'
import Tooltip from '@mui/material/Tooltip';
import NotListedLocationIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where("id", "==", `${user1}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const useItems = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("gear") //access "items" collection
      .where("User", "==", user1)
      .orderBy("SubCategory", "asc")
      .orderBy("Brand", "asc")
      .orderBy("Name", "asc")
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItems = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItems); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

const deleteItem = id => {
  if (window.confirm('Are you sure to delete this record?')) {
    firebase
      .firestore()
      .collection("gear")
      .doc(id)
      .delete();
  }
};

function useStateWithDep(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);
  return [value, setValue];
}

const ItemList = ({ editItem, addItem, editing }) => {
  const listItem = useItems();
  const [rows, setRows] = useStateWithDep(listItem);
  const [searched, setSearched] = useState("");
  const requestSearch = (searchedVal) => {
    const filteredRows = listItem.filter((item) => {
      return item.Name.toUpperCase().includes(searchedVal.toUpperCase());
    });
    setRows(filteredRows);
  };
  
  

  const cancelSearch = () => {
    setSearched("");
    requestSearch(searched);
  };

  function descendingComparator(a, b, orderBy) {
    // console.log('a',a)
    // console.log('b',b)
    // console.log('orderBy',orderBy)
    if (b[orderBy] < a[orderBy]) {
      // console.log('-1')
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      // console.log('1')
      return 1;
    }
    // console.log('0')
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index])
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order;
      return a[1] - b[1];
    })
    return stabilizedRowArray.map((el) => el[0])
  }


  const [orderDirection, setorderDirection] = useState("asc")
  const [valueToOrderBy, setvalueToOrderBy] = useState("SubCategory")

  const createSortHandler = (property) => (event) => {
    setvalueToOrderBy(property);
    if (orderDirection == "desc") {
      setorderDirection("asc");
    } else {
      setorderDirection("desc");
    }
  };

  const [tableData, setTableData] = React.useState(listItem);
  const [selected, setSelected] = React.useState("");

  function handleChange(event) {
    setSelected(event.target.value);
    let _vals = event.target.value
      ? listItem.filter(r => r.Category === event.target.value)
      : listItem;
    setRows(_vals);
  }

  const naa = useItemsUser()
  const newata = naa[0]

  const getBrand = (item) => {
    if (item.BrandId) {
      const foundBrand = newata?.Brand?.find((element) => element.id == item.BrandId)
      if (foundBrand) {
        return foundBrand.label
      }
    }
    return item.Brand ?? '- NO BRAND'
  }

  const getSubCategory = (item) => {
    if (item.CategoryId) {
      const foundCategory = newata?.GearCategories?.find((element) => element.id == item.CategoryId)
      if (foundCategory) {
        if (item.SubCategoryId) {
          const foundSubCategory = foundCategory.subCategory?.find((element) => element.id == item.SubCategoryId)
          if (foundSubCategory) {
            return foundSubCategory.label
          }
        }
      }
    } 
    return item.SubCategory ?? '-'
  }
 
  const getCategory = (key) => {
      const foundCategorykey = categorizedData[key].items[0].CategoryId
      const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
      if (foundCategory) {
      return foundCategory.label
      } else {
        return '-'
      }
  }
 
  const getCategorySort = (key) => {
    const foundCategorykey = categorizedData[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.catid
    } else {
      return '-'
    }
}

  const getCategoryAlt = (item) => {
    const foundCategory = newata?.GearCategories?.find((element) => element.id == item.CategoryId)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

  const getStorageLocation = (item) => {
    if (item.StorageLocationId) {
      const foundStorage = newata?.storageLocation?.find((element) => element.id == item.StorageLocationId)
      if (foundStorage) {
        return foundStorage.label
      }
    }
    return item.StorageLocation ?? '-'
  }

  // Changed from Category to CategoryId

  const categorizedData = listItem.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, BrandId, Qty, Owner, Status, Weight, RepairNotes, LoanNotes, Kit, KitDetails, LoanedTo, ReplaceDate, StorageLocation, StorageLocationId, ProofValue, ProofReceipt, ProofReceiptName, WarrantyExpiry, ProofPhoto, ProofPhotoName, ProofManual, ProofManualName, catid } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, BrandId, Qty, Owner, Status, Weight, RepairNotes, LoanNotes, Kit, KitDetails, LoanedTo, ReplaceDate, StorageLocation, StorageLocationId, ProofValue, ProofReceipt, ProofReceiptName, WarrantyExpiry, ProofPhoto, ProofPhotoName, ProofManual, ProofManualName, id, catid });
    return acc;
  }, {});
 
  const categorizedData2 = listItem.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, BrandId, Qty, Owner, Status, Weight, RepairNotes, LoanNotes, Kit, KitDetails, LoanedTo, ReplaceDate, StorageLocation, StorageLocationId, ProofValue, ProofReceipt, ProofReceiptName, WarrantyExpiry, ProofPhoto, ProofPhotoName, ProofManual, ProofManualName } = curr;
    if (!acc[StorageLocation]) {
      acc[StorageLocation] = {
        items: [],
      };
    }
    acc[StorageLocation].items.push({ Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, BrandId, Qty, Owner, Status, Weight, RepairNotes, LoanNotes, Kit, KitDetails, LoanedTo, StorageLocation, StorageLocationId, id });
    return acc;
  }, {});

  const aawaa = newata?.Travellers?.length ? [...newata?.Travellers] : []
  const aawasa = [...aawaa]
  aawasa?.sort(function (a, b) {
    var textA = a.Name.toUpperCase();
    var textB = b.Name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  aawasa?.unshift({
    Name: 'Any'
  })
  aawasa?.push({
    Name: 'Shared'
  })

  const aawa = newata?.GearCategories?.length ? [...newata?.GearCategories] : []
  const aawas = [...aawa]
  aawas?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  aawas?.unshift({
    label: 'ANY'
  })
  const aawa2 = newata?.storageLocation?.length ? [...newata?.storageLocation] : []
  const aawas2 = [...aawa2]
  aawas2?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  aawas2?.unshift({
    label: 'ANY'
  })

  
  const aawa3 = newata?.Brand?.length ? [...newata?.Brand] : []
  const aawas3 = [...aawa3]
  aawas3?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  aawas3?.unshift({
    label: 'ANY'
  })

  
  const newData = Object.keys(categorizedData)?.filter(key => key)
  const datas = newData?.map(el => {
    return {
      category: el
    }
  })

  const toHash = (category) => {
    return category
    .replace(/[,.\- ]/g, '-').toLowerCase();   
  };
  
  const keys = newData.map(toHash);

  const [expanded, setExpanded] = useState(
    keys.reduce((obj, key) => {
      obj[key] = false;
      return obj;
    }, {})
  );

  const toggleExpanded = (key) => {
    setExpanded((prev) => ({
      ...prev,
      [key]: !prev[key]
    }));
  };

  useEffect(() => {
    const hash = window.location.hash.slice(1);
    if (keys.includes(hash)) {
      setExpanded((prev) => ({
        ...prev,
        [hash]: true
      }));
    }
  }, []);

  const newData2 = Object.keys(categorizedData2)?.filter(key => key)
  const datas2 = newData2?.filter(el => {
    if (el !== "null" && el !== "undefined") {
      return true
    }
  })
  const datasStorage = datas2?.map(el => {
    return {
      StorageLocation: el
    }
  })
  datasStorage?.unshift({ StorageLocation: "ANY" })
  const filteredstatus = listItem?.reduce((acc, current) => {
    const x = acc.find(item => item.Status === current.Status);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  datas?.unshift({ category: "ANY" })
  const styles = {
    control: base => ({
      ...base,
      border: 0,
      borderRadius: 0,
      // This line disable the blue border
      boxShadow: "none",
      margin: 0,
      padding: 0,
      zIndex: 9999,
    }),
    valueContainer: (provided, state) => ({
      padding: 0
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children?.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const [searchCategory, setSearchCategory] = useState({ label: "ANY" })
  const [storageLocation, setStorageLocation] = useState({ label: "ANY" })
  const [brandName, setBrandName] = useState({ label: "ANY" })
  const [SearchOwner, setSearchOwner] = useState({ Name: "Any" })

  const handleSelectChange = (e) => {
    setSearchCategory(e)
  }
  const handleSelectChange2 = (e) => {
    setStorageLocation(e)
  }
  const handleSelectChange3 = (e) => {
    setBrandName(e)
  }

  const [status, setstatus] = useState({ Status: "Any" });

  filteredstatus?.unshift({
    Status: "Any"
  })
  const [name, setName] = useState('')
  const [brand, setBrand] = useState('')

  const getid = useHistory()

  useEffect(() => {
    const ia = getid?.location?.search
    const awa = ia?.split('?')
    if (awa?.length > 1) {
      setstatus({ Status: "Any" })
    }
  }, [getid])


  var preferenceforweight = newata?.WeightPref == true || newata?.WeightPref == undefined ? 'kg' : 'lb'
  var preferencefortips = newata?.TipsPref == true || newata?.TipsPref == undefined ? 'tooltip-absolute' : 'notooltip'

  const resetfilter = (e) => {
    setSearchCategory({ label: "ANY" })
  }



  const StatusFilters = () => {
    if (addItem == true || editing == true) {
      return (
        <>
        
        </>
      )
    } else {
      if (status.Status == "Any" && searchCategory.label == "ANY" && storageLocation.label == "ANY" && SearchOwner.Name == "Any" && brandName.label == "ANY" && name !== '') {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                  {name != '' && brand == '' ? (
                    <>
                      {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) && a.SubCategory.localeCompare(b.SubCategory)  && a.Category.localeCompare(b.Category)).map((item, i) => {
                        if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                          return (
                            <>
                              <TableBody key={i}>
                              <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                              </TableBody>
                            </>
                          )
                        }
                      })}
                    </>
                  ) : name == '' && brand != '' ? (
                    <>
                      {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) && a.SubCategory.localeCompare(b.SubCategory)  && a.Category.localeCompare(b.Category)).map((item, i) => {
                        if (item.Brand.toLowerCase().includes(brand.toLowerCase())) {
                          if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                            if (searchCategory.label.includes(item.Category) || searchCategory.label == 'ANY') {
                              if (storageLocation.label.includes(item.StorageLocation) || storageLocation.label == 'ANY') {
                                if (status.Status.includes(item.Status) || status.Status == 'Any') {
                                  if (SearchOwner.Name.includes(item.Owner) || SearchOwner.Name == 'Any') {
                          return (
                            <>
                              <TableBody key={i}>
                              <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                              </TableBody>
                            </>
                          )
                        } } } } } } 
                      })}
                    </>
                  ) : name != '' && brand != '' ? (
                    <>
                      {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) && a.SubCategory.localeCompare(b.SubCategory) && a.Category.localeCompare(b.Category)).map((item, i) => {
                        if (item.Brand.toLowerCase().includes(brand.toLowerCase())) {
                          if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                            if (searchCategory.label.includes(item.Category) || searchCategory.label == 'ANY') {
                              if (storageLocation.label.includes(item.StorageLocation) || storageLocation.label == 'ANY') {
                                if (status.Status.includes(item.Status) || status.Status == 'Any') {
                                  if (SearchOwner.Name.includes(item.Owner) || SearchOwner.Name == 'Any') {
                            return (
                              <>
                                <TableBody key={i}>
                                <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                </TableBody>
                              </>
                            )
                          } } } } } 
                        }
                      })}
                    </>
                  ) : null}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      } //// Copy
      else if (searchCategory.label != "ANY" && SearchOwner.Name != "Any" && storageLocation.label == "ANY") {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  CATEGORY / SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>

                  {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) && a.SubCategory.localeCompare(b.SubCategory)  && a.Category.localeCompare(b.Category)).map((item, i) => {
                    if (SearchOwner.Name == item.Owner) {
                      if (searchCategory.label == item.Category) {
                        if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                          return (
                            <>
                              <TableBody key={i}>
                              <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                              </TableBody>
                            </>
                          )
                        }
                      
                    } }
                  })}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      }
      else if (status.Status == "Any" && searchCategory.label == "ANY" && storageLocation.label == "ANY" && SearchOwner.Name != "Any") {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  CATEGORY / SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>

                  {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) || a.Name.localeCompare(b.Name)).map((item, i) => {
                    if (SearchOwner.Name == item.Owner) {
                      if (brandName.label == item.Brand || brandName.label == 'ANY') {
                      if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                        return (
                          <>
                            <TableBody key={i}>
                            <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                            </TableBody>
                          </>
                        )
                      }
                    }}
                  })}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      }
      else if (brandName.label != "ANY") {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  CATEGORY / SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                  {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) || a.Name.localeCompare(b.Name)).map((item, i) => {
                    if (storageLocation.label == item.StorageLocation || storageLocation.label == 'ANY') {
                      if (brandName.label == item.Brand || brandName.label == 'ANY') {
                        if (SearchOwner.Name == item.Owner || SearchOwner.Name == 'Any') {

                      if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                        return (
                          <>
                            <TableBody key={i}>
                            <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                            </TableBody>
                          </>
                        )
                      } } }
                    }
                  })}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      }
      else if (status.Status == "Any" && searchCategory.label == "ANY" && storageLocation.label != "ANY") {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  CATEGORY / SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                  {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) || a.Name.localeCompare(b.Name)).map((item, i) => {
                    if (storageLocation.label == item.StorageLocation) {
                      if (brandName.label == item.Brand || brandName.label == 'ANY') {
                      if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                        if (SearchOwner.Name == item.Owner || SearchOwner.Name == 'Any') {
                        return (
                          <>
                            <TableBody key={i}>
                            <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                            </TableBody>
                          </>
                        )
                      } } }
                    }
                  })}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      }
      else if (status.Status != "Any" && storageLocation.label != "ANY" && SearchOwner.Name != 'Any') {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  CATEGORY / SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>

                  {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) && a.SubCategory.localeCompare(b.SubCategory)  && a.Category.localeCompare(b.Category)).map((item, i) => {
                    if (storageLocation.label == item.StorageLocation) {
                      if (SearchOwner.Name == item.Owner) {
                        if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                          if (status.Status == item.Status) {
                            if (searchCategory.label == getCategoryAlt(item) || searchCategory.label == 'ANY') {
                            return (
                              <>
                                <TableBody key={i}>
                                <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                </TableBody>
                              </>
                            )
                          } } 
                        }
                      }
                    }
                  })}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      }
      else if (status.Status != "Any" && storageLocation.label != "ANY") {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  CATEGORY / SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>

                  {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) && a.SubCategory.localeCompare(b.SubCategory) && a.Category.localeCompare(b.Category)).map((item, i) => {
                    if (storageLocation.label == item.StorageLocation) {
                      if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                        if (status.Status == item.Status) {
                          if (searchCategory.label == getCategoryAlt(item) || searchCategory.label == 'ANY') {
                          return (
                            <>
                              <TableBody key={i}>
                              <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                              </TableBody>
                            </>
                          )
                        } } 
                      }
                    }
                  })}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      }
      else if (status.Status == "Any" && searchCategory.label != "ANY" && storageLocation.label != "ANY" && SearchOwner.Name != "Any") {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>

                  {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) && a.SubCategory.localeCompare(b.SubCategory) && a.Category.localeCompare(b.Category)).map((item, i) => {
                    if (searchCategory.label == item.Category) {
                      if (storageLocation.label == item.StorageLocation) {
                        if (SearchOwner.Name == item.Owner) {
                          if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                            return (
                              <>
                                <TableBody key={i}>
                                <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                </TableBody>
                              </>
                            )
                          }
                        }
                      
                    } }
                  })}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      }
      else if (status.Status != "Any" && searchCategory.label != "ANY" && storageLocation.label != "ANY" && SearchOwner.Name != "Any") {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>

                  {listItem.sort((a, b) => a.Brand.localeCompare(b.Brand) && a.SubCategory.localeCompare(b.SubCategory) &&  a.Category.localeCompare(b.Category)).map((item, i) => {
                    if (searchCategory.label == item.Category) {
                      if (storageLocation.label == item.StorageLocation) {
                        if (status.Status == item.Status) {
                          if (SearchOwner.Name == item.Owner) {
                            if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                              return (
                                <>
                                  <TableBody key={i}>
                                  <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                  </TableBody>
                                </>
                              )
                            }
                          }
                        
                      }
                    } }
                  })}
                </Table>
              </div>
            </TableContainer>
          </>
        )
      }

      else if (status.Status != "Any" && searchCategory.label == "ANY" && storageLocation.label == "ANY") {
        return (
          <><br/>
            <TableContainer component={Paper} elevation={3}>
              <div className="table-responsive-vertical">
                <Table className="table tableauto gear-adjust" size="small">
                <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  CATEGORY / SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                  {/* THIS SORT */}
                  {listItem.sort((a, b) => a.Category.localeCompare(b.Category) || a.SubCategory.localeCompare(b.SubCategory)).map((item, i) => {
                    if (status.Status == item.Status) {
                        if (item.Name.toLowerCase().includes(name.toLowerCase())) {
                            if (SearchOwner.Name == item.Owner || SearchOwner.Name == 'Any') {
                            return (
                              <>
                                <TableBody key={i}>
                                <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                </TableBody>
                              </>
                            )
                          }
                        
                      }
                      
                    }
                  })}
                </Table>
              </div>
            </TableContainer>
          </>

        )
      } else if (searchCategory.label == "ANY" || searchCategory.label != "ANY") {
        return (
          <>
          <div className="desktophide mobilesort">
          <label>SORT BY</label>
          <Button variant="outlined" size="small" onClick={createSortHandler("SubCategory")}>SUBCATEGORY</Button>
          <Button variant="outlined" size="small" onClick={createSortHandler("Weight")}>WEIGHT</Button>
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            {
              Object.keys(categorizedData).map((key, index) => {
                categorizedData[key].items?.sort(function (a, b) {
                  var textA = a.Brand.toUpperCase();
                  var textB = b.Brand.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                categorizedData[key].items?.sort(function (a, b) {
                  var textA = a.SubCategory.toUpperCase();
                  var textB = b.SubCategory.toUpperCase();
                  return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                return (
                  <React.Fragment key={index}>
                    {searchCategory.label == getCategory(key) ? <h3 style={{marginTop:'0'}}>{getCategory(key)}</h3> : null }
                    {searchCategory.label == getCategory(key) ?
                    <TableContainer component={Paper} elevation={3}>
                      <div className="table-responsive-vertical">
                        <Table className="table tableauto gear-adjust" size="small">
                          {searchCategory.label == getCategory(key) || searchCategory.label == "ANY" ? (
                            <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                          ) : null}
                          <TableBody>
                            { /* INITIAL */ }
                            {sortedRowInformation(categorizedData[key].items, getComparator(orderDirection, valueToOrderBy)).map((item, index) => {
                              if (getCategory(key) == searchCategory.label || searchCategory.label == "ANY") {
                                if (brand == '') {
                                  if (status.Status == item.Status || status.Status == "Any") {
                                    if (storageLocation.label == item.StorageLocation || storageLocation.label == 'ANY') {
                                    if (searchCategory.label == getCategory(key) || searchCategory.label == 'ANY') {
                                    if (item?.Name?.toLowerCase()?.includes(name?.toLowerCase())) {
                                      return (
                                        <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                      )
                                    } } } 
                                  } else {
                                    return null
                                  }
                                } else if (brand != '' && name == '') {
                                  if (status.Status == item.Status || status.Status == "Any") {
                                    if (item.Brand.toLowerCase().includes(brand.toLowerCase())) {
                                      return (
                                        <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                      )
                                    }
                                  } else {
                                    return null
                                  }
                                } else {
                                  return null
                                }
                              } else {
                                return null
                              }
                            }
                            )}

                          </TableBody>
                        </Table>
                      </div>
                    </TableContainer> : null }

                    {searchCategory.label == "ANY" ? (
                      <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" key={key}
                      id={key.replace(/[,.\- ]/g, '-').toLowerCase()}
                      expanded={expanded[key]}
                      onChange={() => toggleExpanded(key)}
                      style={{order:getCategorySort(key), margin: '3px 0'}}
                      >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                      {key == '' || key == null ? <h3 style={{color: 'red', margin: '0'}}>UNASSIGNED</h3> : <h3 style={{margin:'0'}}>{getCategory(key)}</h3>}


                    </Typography></AccordionSummary>
                    { /* DEFAULT */ }
                    <AccordionDetails>
                    <TableContainer component={Paper} elevation={3}>
                      <div className="table-responsive-vertical">
                        <Table className="table tableauto gear-adjust" size="small">
                          {searchCategory.label == getCategory(key) || searchCategory.label == "ANY" ? (
                            <TableHead>
                              <TableRow>
                                <TableCell className="threehundred"><TableSortLabel
                                  active={valueToOrderBy === "SubCategory"}
                                  direction={valueToOrderBy === "SubCategory" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("SubCategory")}
                                >
                                  SUBCATEGORY
                              </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel
                                  active={valueToOrderBy === "Brand"}
                                  direction={valueToOrderBy === "Brand" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Brand")}
                                >
                                  BRAND
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel
                                  active={valueToOrderBy === "Name"}
                                  direction={valueToOrderBy === "Name" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Name")}
                                >
                                  NAME
                                  </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Owner"}
                                  direction={valueToOrderBy === "Owner" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Owner")}
                                >
                                  OWNER
                        </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Status"}
                                  direction={valueToOrderBy === "Status" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Status")}
                                >
                                  STATUS
                        </TableSortLabel></TableCell> */ }
                                <TableCell className="onehundredfifty"><TableSortLabel
                                  active={valueToOrderBy === "StorageLocation"}
                                  direction={valueToOrderBy === "StorageLocation" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("StorageLocation")}
                                >
                                  STORAGE
                        </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel
                                  active={valueToOrderBy === "Qty"}
                                  direction={valueToOrderBy === "Qty" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Qty")}
                                >
                                  QUANTITY
                        </TableSortLabel></TableCell>
                                <TableCell className="eighty"><TableSortLabel
                                  active={valueToOrderBy === "Weight"}
                                  direction={valueToOrderBy === "Weight" ? orderDirection : 'asc'}
                                  onClick={createSortHandler("Weight")}
                                >
                                  WEIGHT
                        </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                          ) : null}
                          <TableBody>
                            { /* INITIAL */ }
                            {sortedRowInformation(categorizedData[key].items, getComparator(orderDirection, valueToOrderBy)).map((item, index) => {
                              if (getCategory(key) == searchCategory.label || searchCategory.label == "ANY") {
                                  if (status.Status == item.Status || status.Status == "Any") {
                                    if (storageLocation.label == item.StorageLocation || storageLocation.label == 'ANY') {
                                    if (item?.Name?.toLowerCase()?.includes(name?.toLowerCase())) {
                                      return (
                                        <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                      )
                                    } 
                                  } else {
                                    return null
                                  }
                                } else if (brand != '' && name == '') {
                                  if (status.Status == item.Status || status.Status == "Any") {
                                    if (item.Brand.toLowerCase().includes(brand.toLowerCase())) {
                                      return (
                                        <TableRow hover key={item.id} id={item.id} onClick={() => editItem(item)}>
                                          <TableCell data-title="Category">{getCategoryAlt(item)} / {getSubCategory(item)}</TableCell>
                                          <TableCell data-title="Brand" className="hidecell">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}</TableCell>
                                          <TableCell data-title="Name Combined" data-additional={getBrand(item) == "- NO BRAND" ? '-' : getBrand(item)}><div className="desktophide">{item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null}</div>{item.Name}</TableCell>
                                          <TableCell data-title="Location">{item.Owner ? item.Owner : '-'}</TableCell>
                                          { /* <TableCell data-title="Status">{item.Status ? item.Status : '-'}</TableCell> */ }
                                          <TableCell className="mobilehide" data-title="Location">{getStorageLocation(item)}</TableCell>
                                          <TableCell data-title="Quantity">{item.Qty ? item.Qty : '-'}</TableCell>
                                          <TableCell data-title="Weight" className="topright">{item.Weight ? Number(item.Weight).toFixed(3) + preferenceforweight : '-'}</TableCell>
                                        </TableRow>
                                      )
                                    }
                                  } else {
                                    return null
                                  }
                                } else {
                                  return null
                                }
                              } else {
                                return null
                              }
                            }
                            )}

                          </TableBody>
                        </Table>
                      </div>
                    </TableContainer>
                    </AccordionDetails>
                    </Accordion>
                    ) : null}
                  </React.Fragment>
                )
              })
            }
            </div>
          </>
          ) 
      }
    }
  }
  return (
    <>
      {addItem == true || editing == true ? null : (
        <>
        <Accordion elevation={0} defaultExpanded={false} className="filter-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        style={{marginTop:'0'}}
                      >
                        <Typography>
                      <h3>FILTER GEAR</h3>
                    </Typography></AccordionSummary>
                    <AccordionDetails>
        <div className="travellerscontainer" style={{marginBottom:'0'}}>
        <label className="travellerslabel">Status</label>
        <RadioGroup row aria-label="position" name="position" defaultValue="Any">
                <FormControlLabel value="Any" control={<Radio onChange={(e) => setstatus({ Status: "Any" })} color="primary" />} label="Any" />
                <FormControlLabel value="Ready" className="ready-radio" control={<Radio onChange={(e) => setstatus({ Status: "Ready" })} color="primary" />} label="Ready" />
                <FormControlLabel value="Repair" className="repair-radio" control={<Radio onChange={(e) => setstatus({ Status: "Repair" })} color="primary" />} label="Repair" />
                <FormControlLabel value="Buy" className="buy-radio" control={<Radio onChange={(e) => setstatus({ Status: "Buy" })} color="primary" />} label="Buy" />
                { /* <FormControlLabel value="Sell" className="buy-sell" control={<Radio onChange={(e) => setstatus({ Status: "Sell" })} color="primary" />} label="Sell" /> */ }
              </RadioGroup>
        </div>
        <div className="filter-container">
        <Select menuPortalTarget={document.body} fullWidth placeholder="Brand"
                options={aawas3}
                onChange={(e) => handleSelectChange3(e)}
                getOptionLabel={x => x.label}
                getOptionValue={x => x.label}
                className="select"
                components={{
                  ValueContainer: CustomValueContainer
                }}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft: "11px"
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding: "0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -9 : "50%",
                    left: "-4px",
                    background: "#fff",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 12
                  })
                }}
              />
          <Select menuPortalTarget={document.body} fullWidth placeholder="Storage"
                options={aawas2}
                onChange={(e) => handleSelectChange2(e)}
                getOptionLabel={x => x.label}
                getOptionValue={x => x.label}
                className="select"
                components={{
                  ValueContainer: CustomValueContainer
                }}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft: "11px"
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding: "0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none",
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -9 : "50%",
                    left: "-4px",
                    background: "#fff",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 12
                  })
                }}
              />
              <Select menuPortalTarget={document.body} fullWidth placeholder="Owner"
                options={aawasa}
                onChange={(e) => setSearchOwner({ Name: e.Name })}
                getOptionLabel={x => x.Name}
                getOptionValue={x => x.Name}
                className="select"
                components={{
                  ValueContainer: CustomValueContainer
                }}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft: "11px"
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding: "0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none"
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -9 : "50%",
                    left: "-4px",
                    background: "#fff",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 12
                  })
                }}
              />
        </div>
        </AccordionDetails>
        </Accordion>
        </>
      )}
      {StatusFilters()}
    </>
  );
};

export default ItemList;