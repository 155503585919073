import React, { useState, useEffect, useRef } from "react";
import Select, { components } from 'react-select';
import firebase, { storage } from "../../config"

import NumberFormat from "react-number-format";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FormDialog from '../modal'
import FormDialogs from '../modalcategory'
import TextArea from '../TextArea'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotListedLocationIcon from '@material-ui/icons/Help';
import AddCircle from '@material-ui/icons/AddCircle';
import { confirm } from "react-confirm-box";

import { format } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where("id", "==", `${user1}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};



const AddItemForm = ({ setAddItem, addItem, expanded, dirty, setDirty }) => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;


  //useState() hook captures the value from the input value
  const [name, setName] = useState("")
  const [owner, setOwner] = useState("")
  const [ownerset, setOwnerSet] = useState("")
  const [qty, setQty] = useState(Number("1"))
  const [proofvalue, setProofValue] = useState("")
  const [proofreceipt, setProofReceipt] = useState("")
  const [proofreceiptname, setProofReceiptName] = useState("")
  const [warrantyexpiry, setWarrantyExpiry] = useState(null)
  const [proofphoto, setProofPhoto] = useState("")
  const [proofphotoname, setProofPhotoName] = useState("")
  const [proofmanual, setProofManual] = useState("")
  const [proofmanualname, setProofManualName] = useState("")
  const [position, setPosition] = useState("Ready")
  const [weight, setWeight] = useState("")
  const [gearcat, setGearCat] = useState("")
  const [gearsubcat, setGearSubCat] = useState("")
  const [brand, setBrand] = useState("")
  const [brands, setBrands] = useState("")
  const [storagelocationpre, setStorageLocationPre] = useState("")
  const [storagelocation, setStorageLocation] = useState("")
  const [kitdetails, setKitDetails] = useState([])
  const [replacedate, setReplaceDate] = useState(null)
  const [repairnotes, setRepairNotes] = useState("")
  const [loannotes, setLoanNotes] = useState("")
  const [checkedA, setKitSwitch] = useState(false)
  const [country, setCountry] = useState(null)
  const [lang, setLang] = useState(null);
  const [langList, setLangList] = useState([])
  

  function usePersistentObject(init, deps) {
    const obj = useRef(null);
    const prevDeps = useRef(deps || []);
  
    if (obj.current === null || !(prevDeps.current.length === deps.length && prevDeps.current.every((v, i) => deps[i] === v))) { // rough shallow equals, could use a library
      // either first render or deps array values changed
      obj.current = init();    // compute
      prevDeps.current = deps; // save dependency state
    }
    return obj.current;
  
  
  }
  
  const ref = usePersistentObject(
    () => firebase.firestore().collection('gear').doc(),
    [name] // no dependencies, don't recompute
  );
  
  const gearid = ref.id;
  
  // handle change event of the country dropdown
  const handleCountryChange = (obj) => {
    setDirty(true);
    bottomnavtarget.classList.add('cf');
    setCountry(obj);
    setLangList(obj.subCategory);
    setLang(null);
    setGearCat(obj.label);
  };

  // handle change event of the language dropdown
  const handleLanguageChange = (obj) => {
    setDirty(true);
    bottomnavtarget.classList.add('cf');
    setLang(obj);
    setGearSubCat(obj.label);
  };

  const handleBrandChange = (obj) => {
    setDirty(true);
    bottomnavtarget.classList.add('cf');
    setBrands(obj.label);
    setBrand(obj);
  };

  const handleOwnerChange = (e) => {
    setDirty(true);
    bottomnavtarget.classList.add('cf');
    setOwner(e.Name);
    setOwnerSet(e);
  };

  const handleLocationChange = (obj) => {
    setDirty(true);
    bottomnavtarget.classList.add('cf');
    setStorageLocation(obj.label);
    setStorageLocationPre(obj);
  }
  /* The onSubmit function we takes the 'e' 
    or event and submits it to Firebase
    */
  const onSubmit = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
    */
    e.preventDefault();
    firebase
      .firestore()
      .collection("gear")
      .add({
        Name: name,
        Owner: owner,
        Qty: qty,
        Status: position == '' ? 'Ready' : position,
        RepairNotes: repairnotes,
        LoanNotes: loannotes,
        Kit: checkedA,
        KitDetails: kitdetails,
        ReplaceDate: replacedate,
        ProofValue: proofvalue,
        ProofReceipt: proofreceipt,
        ProofReceiptName: proofreceiptname,
        WarrantyExpiry: warrantyexpiry,
        ProofPhoto: proofphoto,
        ProofPhotoName: proofphotoname,
        ProofManual: proofmanual,
        ProofManualName: proofmanualname,
        Weight: weight,
        Brand: brands,
        BrandId: brand ? brand.id : null,
        Category: gearcat,
        CategoryId: country ? country.id : null,
        SubCategory: gearsubcat,
        SubCategoryId: lang ? lang.id : null,
        StorageLocation: storagelocation,
        StorageLocationId: storagelocationpre ? storagelocationpre.id : null,
        User: user1,
      })
      //.then will reset the form to nothing
      .then(() => setAddItem(!addItem), setName(""), setOwnerSet(""), setOwner(""), setQty("1"), setGearCat(""), setGearSubCat(""), setWeight(""), setCountry(""), setLang(""), setBrand(""), setBrands(""), setPosition(''), setKitDetails([]), setKitSwitch(false), setStorageLocationPre(""), setStorageLocation(""), setReplaceDate(null), setWarrantyExpiry(null))
      .finally(() => window.location.reload())
      setAddItem(!addItem)
  };
  
  const style = {
    control: base => ({
      ...base,
      border: 0,
      borderRadius: 0,
      // This line disable the blue border
      boxShadow: "none",
      margin: 0,
      padding: 0,
      zIndex: 9999,
    }),
    valueContainer: (provided, state) => ({
      padding: 0,

    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }

  const [state, setState] = React.useState({
    checkedA: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setKitSwitch(true);
  };

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  const handleChangeA = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setAddItem(!addItem)
  };
  const data = useItemsUser()
  const getdata = data.length ? data[0] : []
  
  const getBrand = getdata?.Brand?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  const getCategory = getdata?.GearCategories?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const getSorageL = getdata?.storageLocation?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const getSub = langList?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  const gettraiveler = getdata?.Travellers?.sort(function (a, b) {
    var textA = a.Name.toUpperCase();
    var textB = b.Name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  const getdataaa = [];
  gettraiveler?.map(el => {
    getdataaa?.push(el)
    return {
      ...el
    }
  })
  getdataaa?.push({ Name: "Shared", Weight: "0.00" })
  const lessthen = 0;

  const handleClearClick = () => {
    setWeight("");
  };

  let inputRef;
  let inputRef2;
  let inputRef3;

  const handleInputUpload = (event) => {
      var file = event.target.files[0]; // get the supplied file
      setProofReceiptName('Uploading...');
        // async magic goes here...
        if (file === null) {
          console.error(`not an image, the image file is a`)
        }
        const uploadTask = storage.ref(`${user1}/${gearid}/${file.name}`).put(file)
    //initiates the firebase side uploading 
    uploadTask.on('state_changed',
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        console.log(snapShot)
      }, (err) => {
        //catches the errors
        console.log(err)
      }, () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage.ref(`${user1}/${gearid}`).child(file.name).getDownloadURL()
          .then(fireBaseUrl => {
            setProofReceipt(fireBaseUrl);  
            setProofReceiptName(file.name);          
            // alert("File Upload Successfully")
          })
      })

   };

   const handleInputUpload2 = (event) => {
    var file = event.target.files[0]; // get the supplied file
    setProofPhotoName('Uploading...');
      // async magic goes here...
      if (file === null) {
        console.error(`not an image, the image file is a`)
      }
      const uploadTask = storage.ref(`${user1}/${gearid}/${file.name}`).put(file)
  //initiates the firebase side uploading 
  uploadTask.on('state_changed',
    (snapShot) => {
      //takes a snap shot of the process as it is happening
      console.log(snapShot)
    }, (err) => {
      //catches the errors
      console.log(err)
    }, () => {
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      storage.ref(`${user1}/${gearid}`).child(file.name).getDownloadURL()
        .then(fireBaseUrl => {
          setProofPhoto(fireBaseUrl);   
          setProofPhotoName(file.name);         
          // alert("File Upload Successfully")
        })
    })

 };

 const handleInputUpload3 = (event) => {
  var file = event.target.files[0]; // get the supplied file
  setProofManualName('Uploading...');
    // async magic goes here...
    if (file === null) {
      console.error(`not an image, the image file is a`)
    }
    const uploadTask = storage.ref(`${user1}/${gearid}/${file.name}`).put(file)
//initiates the firebase side uploading 
uploadTask.on('state_changed',
  (snapShot) => {
    //takes a snap shot of the process as it is happening
    console.log(snapShot)
  }, (err) => {
    //catches the errors
    console.log(err)
  }, () => {
    // gets the functions from storage refences the image storage in firebase by the children
    // gets the download url then sets the image from firebase as the value for the imgUrl key:
    storage.ref(`${user1}/${gearid}`).child(file.name).getDownloadURL()
      .then(fireBaseUrl => {
        setProofManual(fireBaseUrl);   
        setProofManualName(file.name);         
        // alert("File Upload Successfully")
      })
  })

};

const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");

  return (
    <div className="additem additemmain">
      <Accordion elevation={0} expanded={expanded} defaultExpanded={false} onChange={handleChangeA("panel")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{display:'none'}}
        >
        </AccordionSummary>
        <AccordionDetails className="addtripacc additemacc">
          <Typography>
            <form onSubmit={onSubmit} onChange={()=>(setDirty(true), bottomnavtarget.classList.add('cf'))}>
      <div className="gear-brand-col">
              <Select value={brand} menuPortalTarget={document.body} isSearchable={ true } fullWidth placeholder="Search Brand"
              options={getBrand}
              className="select indent"
              onChange={handleBrandChange}
              components={{
                ValueContainer: CustomValueContainer
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary25: '#f1f1f1',
                  primary: '#202020',
                },
              })}
                              styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft: "11px"
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding: "0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none"
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -9 : "50%",
                    left: "-4px",
                    background: state.hasValue || state.selectProps.inputValue ? "#fff" : "transparent",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                  })
                }} />
                 <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Add brand if not listed. Edit all your brands in profile." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
                <div className="mobilehide">
              <FormDialog />
              </div>
              </div>
              <div className="gear-name-col">
              <TextField required fullWidth id="standard-basic" label="Gear Name" value={name} name="name" onChange={e => setName(e.currentTarget.value)} type="text" variant="outlined" />
              </div>
              <div className="gear-cat-col">
              <Select menuPortalTarget={document.body} isSearchable={ false } fullWidth placeholder="Select Category" value={country}
                options={getCategory}
                onChange={handleCountryChange}
                getOptionLabel={x => x.label}
                getOptionValue={x => x.value}
                className="select indent"
                components={{
                  ValueContainer: CustomValueContainer
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f1f1f1',
                    primary: '#202020',
                  },
                })}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft: "11px"
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding: "0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none"
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
                    left: "-4px",
                    background: "#fff",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                  })
                }}
              />
               <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Customise categories and subcategories in profile." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="gear-sub-col">
              <Select menuPortalTarget={document.body} isSearchable={ false } fullWidth
                placeholder="Select Subcategory"
                value={lang}
                options={getSub}
                onChange={handleLanguageChange}
                getOptionLabel={x => x.label}
                getOptionValue={x => x.value}
                className="select indent"
                components={{
                  ValueContainer: CustomValueContainer
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f1f1f1',
                    primary: '#202020',
                  },
                })}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft: "11px",
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding: "0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none"
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
                    left: "-4px",
                    background: "#fff",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                  })
                }}
              />
              <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Customise categories and subcategories in profile." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
              </div>
              <div className="gear-cat-col">
              <div className="travellerscontainer" style={{paddingBottom:'3px', marginBottom:'0'}}>
                    <label className="travellerslabel">Status</label>
                    <RadioGroup row aria-label="position" name="position" defaultValue="Ready" onChange={e => setPosition(e.currentTarget.value)}>
                <FormControlLabel value="Ready" className="ready-radio" control={<Radio color="primary" />} label="Ready" />
                { /* <FormControlLabel value="Charge" control={<Radio color="primary" />} label="Charge" />
                <FormControlLabel value="Fill" control={<Radio color="primary" />} label="Fill" /> */ }
                <FormControlLabel value="Repair" className="repair-radio" control={<Radio color="primary" />} label="Repair" />
                <FormControlLabel value="Buy" className="buy-radio" control={<Radio color="primary" />} label="Buy" />
                { /* <FormControlLabel value="Loaned" control={<Radio color="primary" />} label="Loaned" /> */ }
                { /* <FormControlLabel value="Sell" className="buy-sell" control={<Radio color="primary" />} label="Sell" /> */ }
                <Tooltip style={{top: '2px'}} leaveTouchDelay={3000} enterTouchDelay={50} title="Mark status to assist in keeping gear trip ready." placement="top-start" arrow>
                  <IconButton className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip tooltip-mobile-alt-alt' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                </Tooltip>
              </RadioGroup>
              {position == 'Repair' && (
                <TextareaAutosize placeholder="Notes" label="Repair Details" value={repairnotes} name="repairnotes" onChange={e => setRepairNotes(e.currentTarget.value)} style={{ width: '100%', border: '1px solid #c4c4c4', borderRadius: '3px', resize: 'none', fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', padding: '18.5px 14px', boxSizing: 'border-box', outline: 'none', margin: '10px 0' }}></TextareaAutosize>
              )}
              {position == 'Loaned' && (
                <TextareaAutosize placeholder="Notes" label="Loaned To" value={loannotes} name="loannotes" onChange={e => setLoanNotes(e.currentTarget.value)} style={{ width: '100%', border: '1px solid #c4c4c4', borderRadius: '3px', resize: 'none', fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', padding: '18.5px 14px', boxSizing: 'border-box', outline: 'none', margin: '10px 0' }}></TextareaAutosize>
              )}
        </div>
              </div>
              { /* 
              <div className="gear-sub-col">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={"Replace Date"}
                  inputFormat="dd/MM/yyyy"
                  disableCloseOnSelect={false}
                  disablePast={true}
                  value={replacedate}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    setReplaceDate(format(newValue, 'MM/dd/yyyy'));
                  }}
                  renderInput={(params) => <TextField variant="outlined" {...params} helperText={null} fullWidth />}
                />
              </LocalizationProvider>
              <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select tooltip-mobile-alt' : 'notooltip'} style={{ top: '25px', right: '0', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Even the highest quality gear deteriorates with use and time. Keeping track of replacement dates makes it easier to see when gear is due for retirement and allows you to replace critical gear prior to trips." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
                </div> */ }       
        <div style={{marginBottom: '5px' }} className="travellerscontainer">
                <Tooltip className="travellerstooltipv2" leaveTouchDelay={3000} enterTouchDelay={50} title="Gear can be added as a kit of gear. List the items of the kit in this field rather than as individual items and then weigh the whole kit. This works well for coffee kits or electronics kits that include a number of smaller items." placement="top-start" arrow>
                  <IconButton className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                </Tooltip>
                    <label className="travellerslabel">Kit</label>
              <FormControlLabel control={<Switch checked={state.checkedA} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />} label="" />
              {state.checkedA == true && (
                <>
                  <br /><b>Kit Details</b>
                  <TextArea kitdetails={kitdetails} setKitDetails={setKitDetails} />
                </>
              )}
              
        </div>
        <div className="gear-qty-col">
        <TextField style={{width:'100%'}} required id="standard-basic" label="Quantity" value={qty} name="qty" onChange={e => {
                const nan = Number(e.currentTarget.value)
                if (nan >= lessthen) {
                  setQty(e.currentTarget.value)
                } else {
                  setQty(1)
                }
              }} inputProps={{ inputMode: 'numeric' }} defaultValue="1" fullwidth variant="outlined" />
        </div>
        <div className="gear-weight-col">
        <NumberFormat style={{width:'100%'}} inputProps={{ inputMode: 'decimal',}} allowNegative={false} InputProps={{ endAdornment: <InputAdornment position="end">{getdata?.WeightPref == true || getdata?.WeightPref == undefined ? 'kg' : 'lb'}<IconButton size="small" style={{opacity: '0.5', marginLeft: '10px'}} onClick={handleClearClick}><ClearIcon /></IconButton></InputAdornment> }} label="Weight" customInput={TextField} variant="outlined" name="weight" thousandSeparator={true} decimalScale={3} isNumericString={true} fixedDecimalScale={true} displayType="input" value={weight}
              onChange={(e) => e.target.value === "" ? setWeight(0) : setWeight(e.target.value)} />
        </div>
        <div className="gear-storage-col">
        <Select value={storagelocationpre} menuPortalTarget={document.body} menuPlacement="top" isSearchable={ false } fullWidth placeholder="Storage" options={getSorageL} className="select indent" onChange={handleLocationChange} components={{
                ValueContainer: CustomValueContainer
              }}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft: "11px"
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding: "0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none"
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
                    left: "-4px",
                    background: "#fff",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                  })
                }} />
                <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Customise storage locations in profile." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
        </div>
        </div>
        <div className="gear-owner-col">
        <Select
                menuPortalTarget={document.body}
                menuPlacement="top"
                isSearchable={ false }
                fullWidth
                placeholder="Owner"
                options={getdataaa}
                getOptionLabel={x => x.Name}
                getOptionValue={x => x.Name}
                className="select indent"
                onChange={handleOwnerChange}
                value={ownerset}
                components={{
                  ValueContainer: CustomValueContainer
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: '#f1f1f1',
                    primary: '#202020',
                  },
                })}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    marginTop: 50,
                    paddingLeft: "11px"
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    overflow: "visible",
                    borderColor: "#fff !important",
                    padding: "0 !important"
                  }),
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: "#fff !important",
                    boxShadow: "none"
                  }),
                  placeholder: (provided, state) => ({
                    ...provided,
                    position: "absolute",
                    top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
                    left: "-4px",
                    background: "#fff",
                    padding: "0 5px",
                    transition: "top 0.5s, font-size 0.1s",
                    color: "#A2A2A2",
                    fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                  })
                }} />
                <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Add travellers in profile and then allocate gear to a person or mark as a shared item." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
        </div>
              { /* <FormDialogs /> */ }
              { /* 
              <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
               
              <div style={{marginBottom: '5px' }} className="travellerscontainer">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={"Warranty Expiry"}
                  inputFormat="dd/MM/yyyy"
                  disableCloseOnSelect={false}
                  disablePast={false}
                  value={warrantyexpiry}
                  minDate={new Date()}
                  onChange={(newValue) => {
                    setWarrantyExpiry(format(newValue, 'MM/dd/yyyy'));
                  }}
                  renderInput={(params) => <TextField variant="outlined" {...params} helperText={null} />}
                />
              </LocalizationProvider><span className="mobilehide">&nbsp;&nbsp;</span>
              <input type="file" ref={refParam => inputRef = refParam} hidden={true} onChange={(event, index) => handleInputUpload(event)} name="proofreceipt" accept="image/*,.pdf" />
              <Button className="btn-mobile-alt" size="large" onClick={() => inputRef.click()} variant="outlined" startIcon={<AttachFileIcon />} style={{marginTop:'10px',height:'56px'}}>
              {proofreceiptname ? proofreceiptname : <>RECEIPT</>}
              </Button><span className="mobilehide">&nbsp;&nbsp;</span>
              <TextField id="standard-basic" label="Value" value={proofvalue} name="proofvalue" onChange={e => { setProofValue(e.currentTarget.value)}} inputProps={{ inputMode: 'numeric' }} variant="outlined" /><span className="mobilehide">&nbsp;&nbsp;</span>
              <input type="file" ref={refParam => inputRef2 = refParam} hidden={true} onChange={(event, index) => handleInputUpload2(event)} name="proofphoto" accept="image/*,.pdf" />
              <Button className="btn-mobile-alt" size="large" onClick={() => inputRef2.click()} variant="outlined" startIcon={<AttachFileIcon />} style={{marginTop:'10px',height:'56px'}}>
              {proofphotoname ? proofphotoname : <>PHOTO</>}
              </Button><span className="mobilehide">&nbsp;&nbsp;</span>
              <input type="file" ref={refParam => inputRef3 = refParam} hidden={true} onChange={(event, index) => handleInputUpload3(event)} name="proofmanual" accept="image/*,.pdf" />
              <Button className="btn-mobile-alt" size="large" onClick={() => inputRef3.click()} variant="outlined" startIcon={<AttachFileIcon />} style={{marginTop:'10px',height:'56px'}}>
              {proofmanualname ? proofmanualname : <>MANUAL</>}
              </Button>
              <Tooltip style={{top:'-9px',right:'-11px'}} className="travellerstooltipv2" leaveTouchDelay={3000} enterTouchDelay={50} title="These fields are purely a guide. To ensure policy compliance check requirements with the manufacturer or your insurer." placement="top-start" arrow>
                  <IconButton className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
              </Tooltip>
              <label className="travellerslabel">Proof of Ownership for Warranty & Insurance</label>
                </div>
                
                </div> */ }
                <br/>
                <div className="additem-btn">
              <Button
                disableElevation
                variant="outlined"
                color="default"
                size="large"
                type="submit"
                className="btn blackbtn btnadditem"
              >
                Add Gear
              </Button>
              </div>
            </form>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
export default AddItemForm;