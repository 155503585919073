import React, { useState, useEffect } from "react";
import Select, { components } from 'react-select';
import firebase, { storage } from "../../config"
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import NumberFormat from "react-number-format";
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ClearIcon from '@mui/icons-material/Clear';
import { BulletedTextArea } from "react-bulleted-textarea";
import Typography from '@material-ui/core/Typography';
import FormDialog from '../modal'
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Paper from '@material-ui/core/Paper';
import FormDialogs from '../modalcategory'
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import NotListedLocationIcon from '@material-ui/icons/Help';
import { confirm } from "react-confirm-box";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { format } from 'date-fns'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { isPlatform } from "@ionic/react";
import { FileOpener } from '@awesome-cordova-plugins/file-opener';
import { File } from '@awesome-cordova-plugins/file';
import fileDownload from 'js-file-download';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where("id", "==", `${user1}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};

/* 
So far we have passed the setEditing prop to index.js. 
Now we are going to pass the currentItem prop
*/
const UpdateItem = ({ setEditing,editing, currentItem, updateItem, setAddItem, addItem }) => {
  /*
  Sets the state of the item to the current item
  */

  const headerlogo = document.querySelector(".topnavigation img");
  
  headerlogo.classList.add('indent');
  
  const [item, setItem] = useState(currentItem);

  /*
  Side effect is that without UseEffect if you start editing one item, 
  then try to switch to another item, nothing will happen.
  The component is already open, and although the state 
  on the parent has changed, it's not registered down to the props.
  We want to let the UpdateItem form component know the props have changed.
  With the Effect Hook, we create a callback function that updates the item 
  state with the new prop thats being sent through.
  */

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = e => {
    e.preventDefault();
    item.BrandId = item.BrandId ?? null
    item.CategoryId = item.CategoryId ?? null
    item.SubCategoryId = item.SubCategoryId ?? null
    item.StorageLocationId = item.StorageLocationId ?? null
    item.StorageLocation = item.StorageLocation == undefined ? null : item.StorageLocation
    item.LoanNotes = item.LoanNotes == undefined ? null : item.LoanNotes
    item.ReplaceDate = item.ReplaceDate == undefined || null ? null : item.ReplaceDate
    item.ProofReceipt = item.ProofReceipt == undefined || null ? null : item.ProofReceipt
    item.ProofReceiptName = item.ProofReceiptName == undefined || null ? null : item.ProofReceiptName
    item.ProofValue = item.ProofValue == undefined || null ? null : item.ProofValue
    item.WarrantyExpiry = item.WarrantyExpiry == undefined || null ? null : item.WarrantyExpiry
    item.ProofPhoto = item.ProofPhoto == undefined || null ? null : item.ProofPhoto
    item.ProofPhotoName = item.ProofPhotoName == undefined || null ? null : item.ProofPhotoName
    item.ProofManual = item.ProofManual == undefined || null ? null : item.ProofManual
    item.ProofManualName = item.ProofManualName == undefined || null ? null : item.ProofManualName
    updateItem({ currentItem }, item);
  };
  const lessthen = 0;
  const onChange = e => {
    const { name, value } = e.target;
    const nan = Number(value)
    if (nan >= lessthen) {
      setItem({ ...item, [name]: nan });
    } else {
      setItem({ ...item, [name]: 1 });
    }
  };
  const onChange2 = e => {
    const { name, value } = e.target;
    setItem({ ...item, [name]: value });
  };

  const onChange2Weight = e => {
    const { name, value } = e.target;
    if (e.target.value === "") {
      setItem({ ...item, [name]: 0 });
    } else {
      setItem({ ...item, [name]: value });
  }
  };

  const [brandItem, setBrandItem] = useState({ label: item.Brand, id : item.BrandId ?? "" })
  
  const handleBrandChange = (obj) => {
    setBrandItem(obj)
    setItem({ ...item, Brand: obj.label, BrandId: obj.id });
  };

  const [storageLocation, setStorageLocation] = useState({ label: item.StorageLocation, id : item.StorageLocationId ?? "" })
  const handleStorageLocationChange = (obj) => {
    setStorageLocation(obj)
    setItem({ ...item, StorageLocation: obj.label, StorageLocationId: obj.id });
  };


  const [country, setCountry] = useState({ label: item.Category, id : item.CategoryId ?? "" });
  const [lang, setLang] = useState(null);
  const [langList, setLangList] = useState([]);

  const handleCategoryChange = (obj) => {
    setCountry(obj);
    setLangList(obj.subCategory);
    setLang(null);
    setItem({ ...item, Category: obj.label, CategoryId: obj.id });
  };

  const [subCategory, setSubCategory] = useState({ label: item.SubCategory, id: item.SubCategoryId ?? "" })
  const handleSubCategoryChange = (obj) => {
    setSubCategory(obj)
    setItem({ ...item, SubCategory: obj.label, SubCategoryId: obj.id });
  };

  const style = {
    control: base => ({
      ...base,
      border: 0,
      borderRadius: 0,
      // This line disable the blue border
      boxShadow: "none",
      margin: 0,
      padding: 0,
      zIndex: 9999,
    }),
    valueContainer: (provided, state) => ({
      padding: 0,

    }),
    menuPortal: provided => ({ ...provided, zIndex: 25 }),
    menu: provided => ({ ...provided, zIndex: 10000000000000 }),
  }

  const handleChange = (event) => {
    setItem({ ...item, Kit: event.target.checked });
  };

  const handleChangeRD = (event) => {
    setItem({ ...item, ReplaceDate: event.value });
  };

  const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: "Yes",
      cancellable: "No"
    }
  };

  const deleteItem = async (id,options) => {
    const result = await confirm("Are you sure you want to delete this gear?", optionsWithLabelChange);
    if (result) {
      firebase
        .firestore()
        .collection("gear")
        .doc(id)
        .delete();
      setEditing(false);
    }
  };
  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };
  const [val, setval] = useState({ Name: item?.Owner })
  const data = useItemsUser()
  const getdata = data?.length ? data[0] : []


  /*useEffect(() => {
    const filtwa = getdata?.GearCategories?.filter(el => el.label == item.Category ? true : false)
    if (filtwa?.length) {
      setLangList(filtwa[0]?.subCategory)
    }
  }, [getdata?.length])*/
  const getBrand = getdata?.Brand?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const getCategory = getdata?.GearCategories?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const getSorageL = getdata?.storageLocation?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const getSubs = langList?.sort(function (a, b) {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const gettraiveler = getdata?.Travellers?.sort(function (a, b) {
    var textA = a.Name.toUpperCase();
    var textB = b.Name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const getdataaa = [];
  gettraiveler?.map(el => {
    getdataaa?.push(el)
    return {
      ...el
    }
  })
  getdataaa?.push({ Name: "Shared", Weight: "0.00" })

  const history = useHistory()

  let intervalID;
  useEffect(() => {
    if (editing) {
      intervalID = setInterval(() => {
        const formSubmitButton = document.querySelector(".submitauto");
        formSubmitButton.click();
      }, 1500);
    }
    return () => clearInterval(intervalID);
  }, [editing]); 

  const handleChangeOwner = (e) => {
    setval({ Name: e.Name })
    setItem({ ...item, Owner: e.Name });
  }
  const bulletChar = '•'
  const newarra = typeof item.KitDetails == 'string' ? [item.KitDetails] : item.KitDetails;

  const handleClearClick = e => {
    setItem({ ...item, Weight: '' });
  };

  const getBrandLabel = (item) => {
    if (item.BrandId) {
      const foundBrand = getdata?.Brand?.find((element) => element.id == item.BrandId)
      if (foundBrand) {
        return foundBrand.label
      }
    }
    return item.Brand ?? ""
  }

  const getCategoryLabel = (item) => {
    if (item.CategoryId) {
      const foundCategory = getdata?.GearCategories?.find((element) => element.id == item.CategoryId)
      if (foundCategory) {
        return foundCategory.label
      }
    }
    return item.Category ?? ""
  }

  const getSubCategory = (item) => {
    let foundCategory
    if (item.CategoryId) {
      foundCategory = getdata?.GearCategories?.find((element) => element.id == item.CategoryId)
    } else if (item.Category) {
      foundCategory = getdata?.GearCategories?.find((element) => element.label == item.Category)
    }
    if (foundCategory) {
      setLangList(foundCategory.subCategory)
      if (item.SubCategoryId) {
        const foundSubCategory = foundCategory.subCategory?.find((element) => element.id == item.SubCategoryId)
        if (foundSubCategory) {
          return foundSubCategory.label
        }
      }
    }
    return item.SubCategory ?? ""
  }

  const getStorageLocation = (item) => {
    if (item.CategoryId) {
      const foundStorage = getdata?.storageLocation?.find((element) => element.id == item.CategoryId)
      if (foundStorage) {
        return foundStorage.label
      }
    }
    return item.StorageLocation ?? ""
  }

  let inputRef;
  let inputRef2;
  let inputRef3;

  const [proofreceiptname, setProofReceiptName] = useState("RECEIPT")
  const [proofphotoname, setProofPhotoName] = useState("PHOTO")
  const [proofmanualname, setProofManualName] = useState("MANUAL")

  const user = firebase.auth().currentUser;
  const user1 = user.uid;

  const handleInputUpload = (event) => {
    var file = event.target.files[0]; // get the supplied file
    setProofReceiptName('Uploading...');
      // async magic goes here...
      if (file === null) {
        console.error(`not an image, the image file is a`)
      }
      const uploadTask = storage.ref(`${user1}/${item.id}/${file.name}`).put(file)
  //initiates the firebase side uploading 
  uploadTask.on('state_changed',
    (snapShot) => {
      //takes a snap shot of the process as it is happening
      console.log(snapShot)
    }, (err) => {
      //catches the errors
      console.log(err)
    }, () => {
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      storage.ref(`${user1}/${item.id}`).child(file.name).getDownloadURL()
        .then(fireBaseUrl => {
          setItem({ ...item, ["ProofReceipt"]: fireBaseUrl, ["ProofReceiptName"]: file.name });
          setProofReceiptName('Uploaded');          
          // alert("File Upload Successfully")
        })
    })

 };

 const handleInputUpload2 = (event) => {
  var file = event.target.files[0]; // get the supplied file
  setProofPhotoName('Uploading...');
    // async magic goes here...
    if (file === null) {
      console.error(`not an image, the image file is a`)
    }
    const uploadTask = storage.ref(`${user1}/${item.id}/${file.name}`).put(file)
//initiates the firebase side uploading 
uploadTask.on('state_changed',
  (snapShot) => {
    //takes a snap shot of the process as it is happening
    console.log(snapShot)
  }, (err) => {
    //catches the errors
    console.log(err)
  }, () => {
    // gets the functions from storage refences the image storage in firebase by the children
    // gets the download url then sets the image from firebase as the value for the imgUrl key:
    storage.ref(`${user1}/${item.id}`).child(file.name).getDownloadURL()
      .then(fireBaseUrl => {
        setItem({ ...item, ["ProofPhoto"]: fireBaseUrl, ["ProofPhotoName"]: file.name });
        setProofPhotoName('Uploaded');         
        // alert("File Upload Successfully")
      })
  })

};

const handleInputUpload3 = (event) => {
  var file = event.target.files[0]; // get the supplied file
  setProofManualName('Uploading...');
    // async magic goes here...
    if (file === null) {
      console.error(`not an image, the image file is a`)
    }
    const uploadTask = storage.ref(`${user1}/${item.id}/${file.name}`).put(file)
//initiates the firebase side uploading 
uploadTask.on('state_changed',
  (snapShot) => {
    //takes a snap shot of the process as it is happening
    console.log(snapShot)
  }, (err) => {
    //catches the errors
    console.log(err)
  }, () => {
    // gets the functions from storage refences the image storage in firebase by the children
    // gets the download url then sets the image from firebase as the value for the imgUrl key:
    storage.ref(`${user1}/${item.id}`).child(file.name).getDownloadURL()
      .then(fireBaseUrl => {
        setItem({ ...item, ["ProofManual"]: fireBaseUrl, ["ProofManualName"]: file.name });
        setProofManualName('Uploaded');         
        // alert("File Upload Successfully")
      })
  })

};

const handleDownload = (url, filename) => {
  console.log(item);
  if (isPlatform("ios")) {
        console.log('Filename' + filename)
        console.log('URL' + url)
          let pdfOutput = url;
          const directory = File.tempDirectory;
          console.log('Dir' + directory)
          File.writeFile(directory, filename, pdfOutput, true).then(success => {
            FileOpener.showOpenWithDialog(directory + fileName, 'application/pdf')
              .then(() => console.log('File opened'))
              .catch(e => console.log('Error opening file', e));
          },
            err => {
              console.log(" writing File error : ", err)
            })
  } else {
  axios.get(url, {
    responseType: 'blob',
  })
  .then((res) => {
    fileDownload(res.data, filename)
  })
  }
}

  useEffect(() => {
    setBrandItem({ label: getBrandLabel(item), id: item.BrandId ?? "" })
    setval({ Name: item?.Owner })
    setCountry({ label: getCategoryLabel(item), id: item.CategoryId ?? "" })
    setSubCategory({ label: getSubCategory(item), id: item.SubCategoryId ?? "" })
    setStorageLocation({ label: getStorageLocation(item), id: item.StorageLocationId ?? "" })
  }, [item, data])
  
  return (
    <div>
    <div className="backchevron" onClick={() => {
            history.push('/gear/index')
            setEditing(false)
          }}><FontAwesomeIcon icon={faChevronLeft} size="lg" /></div>
    <Paper className="trip-paper" elevation={3}>
      <div id="updateItem" className="updateitem">
      <div className="gear-brand-col">
      <Select
          menuPortalTarget={document.body}
          isSearchable={ true }
          fullWidth
          placeholder="Brand"
          options={getBrand}
          name="Brand"
          value={brandItem}
          getOptionLabel={x => x.label}
          getOptionValue={x => x.id}
          className="select indent"
          onChange={handleBrandChange}
          components={{
            ValueContainer: CustomValueContainer
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f1f1f1',
              primary: '#202020',
            },
          })}
          styles={{
            container: (provided, state) => ({
              ...provided,
              marginTop: 50,
              paddingLeft: "11px"
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              overflow: "visible",
              borderColor: "#fff !important",
              padding: "0 !important"
            }),
            control: (provided, state) => ({
              ...provided,
              borderColor: "#fff !important",
              boxShadow: "none"
            }),
            placeholder: (provided, state) => ({
              ...provided,
              position: "absolute",
              top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
              left: "-4px",
              background: state.hasValue || state.selectProps.inputValue ? "#fff" : "transparent",
              padding: "0 5px",
              transition: "top 0.5s, font-size 0.1s",
              color: "#A2A2A2",
              fontSize: (state.hasValue || state.selectProps.inputValue) && 13
            })
          }}
        />
        <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip title="Add brand if not listed. Edit all your brands in profile." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
      </div>
        { /* <FormDialog /> */ }
        <div className="gear-name-col">
        <TextField required fullWidth id="standard-basic" label="Gear Name" value={item.Name} name="Name" onChange={onChange2} type="text" variant="outlined" />
        </div>
        <div className="gear-cat-col">
        <Select menuPortalTarget={document.body}
          isSearchable={ false }
          fullWidth
          placeholder={"Select Category"}
          value={country}
          options={getCategory}
          onChange={handleCategoryChange}
          getOptionLabel={x => x.label}
          getOptionValue={x => x.id}
          className="select indent"
          components={{
            ValueContainer: CustomValueContainer
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f1f1f1',
              primary: '#202020',
            },
          })}
          styles={{
            container: (provided, state) => ({
              ...provided,
              marginTop: 50,
              paddingLeft: "11px"
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              overflow: "visible",
              borderColor: "#fff !important",
              padding: "0 !important"
            }),
            control: (provided, state) => ({
              ...provided,
              borderColor: "#fff !important",
              boxShadow: "none"
            }),
            placeholder: (provided, state) => ({
              ...provided,
              position: "absolute",
              top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
              left: "-4px",
              background: "#fff",
              padding: "0 5px",
              transition: "top 0.5s, font-size 0.1s",
              color: "#A2A2A2",
              fontSize: (state.hasValue || state.selectProps.inputValue) && 13
            })
          }}
        />
        <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Customise categories and subcategories in profile." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
        </div>
        <div className="gear-sub-col">
        <Select
          menuPortalTarget={document.body}
          isSearchable={ false }
          fullWidth
          placeholder={"Select Subcategory"}
          value={subCategory}
          options={getSubs}
          className="select indent"
          onChange={handleSubCategoryChange}
          getOptionLabel={x => x.label}
          getOptionValue={x => x.id}
          components={{
            ValueContainer: CustomValueContainer
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f1f1f1',
              primary: '#202020',
            },
          })}
          styles={{
            container: (provided, state) => ({
              ...provided,
              marginTop: 50,
              paddingLeft: "11px"
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              overflow: "visible",
              borderColor: "#fff !important",
              padding: "0 !important"
            }),
            control: (provided, state) => ({
              ...provided,
              borderColor: "#fff !important",
              boxShadow: "none"
            }),
            placeholder: (provided, state) => ({
              ...provided,
              position: "absolute",
              top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
              left: "-4px",
              background: "#fff",
              padding: "0 5px",
              transition: "top 0.5s, font-size 0.1s",
              color: "#A2A2A2",
              fontSize: (state.hasValue || state.selectProps.inputValue) && 13
            })
          }}
        />
        <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Customise categories and subcategories in profile." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
        </div>

        <div className="gear-cat-col">
        <div className="travellerscontainer" style={{paddingBottom:'3px', marginBottom:'0'}}>
                    <label className="travellerslabel">Status</label>
        <RadioGroup row aria-label="position" value={item.Status} name="Status" defaultValue="top" onChange={onChange2} >
          <FormControlLabel value="Ready" className="ready-radio" control={<Radio color="primary" />} label="Ready" />
          { /* <FormControlLabel value="Charge" control={<Radio color="primary" />} label="Charge" />
          <FormControlLabel value="Fill" control={<Radio color="primary" />} label="Fill" /> */ }
          <FormControlLabel value="Repair" className="repair-radio" control={<Radio color="primary" />} label="Repair" />
          <FormControlLabel value="Buy" className="buy-radio" control={<Radio color="primary" />} label="Buy" />
          { /* <FormControlLabel value="Loaned" control={<Radio color="primary" />} label="Loaned" /> */ }
          { /* <FormControlLabel value="Sell" className="buy-sell" control={<Radio color="primary" />} label="Sell" /> */ }
          <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} style={{top: '2px'}} title="Mark status to assist in keeping gear trip ready." placement="top-start" arrow>
                  <IconButton className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip tooltip-mobile-alt-alt' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                </Tooltip>
        </RadioGroup>
        {item.Status == 'Repair' && (
          <>
          <TextareaAutosize placeholder="Notes" label="Repair Details" value={item.RepairNotes} name="RepairNotes" onChange={onChange2} style={{ width: '100%', border: '1px solid #c4c4c4', borderRadius: '3px', resize: 'none', fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', padding: '18.5px 14px', boxSizing: 'border-box', outline: 'none', margin: '10px 0' }}></TextareaAutosize>
          </>
        )}
        {item.Status == 'Loaned' && (
          <>
          <TextareaAutosize placeholder="Notes" label="Loaned To" value={item.LoanNotes} name="LoanNotes" onChange={onChange2} style={{ width: '100%', border: '1px solid #c4c4c4', borderRadius: '3px', resize: 'none', fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', padding: '18.5px 14px', boxSizing: 'border-box', outline: 'none', margin: '10px 0' }}></TextareaAutosize>
          </>
        )}
        </div>
        </div>
        { /*
        <div className="gear-sub-col">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={"Replace Date"}
                  inputFormat="dd/MM/yyyy"
                  disableCloseOnSelect={false}
                  disablePast={true}
                  value={item.ReplaceDate}
                  minDate={new Date()}
                  onChange={(newValue) => setItem({ ...item, ["ReplaceDate"]: format(newValue, 'MM/dd/yyyy') })}
                  renderInput={(params) => <TextField variant="outlined" {...params} helperText={null} fullWidth />}
                />
              </LocalizationProvider> 
              <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select tooltip-mobile-alt' : 'notooltip'} style={{ top: '25px', right: '0', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Even the highest quality gear deteriorates with use and time. Keeping track of replacement dates makes it easier to see when gear is due for retirement and allows you to replace critical gear prior to trips." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
        </div> */ }

        <div style={{marginBottom: '5px' }} className="travellerscontainer">
                    <label className="travellerslabel">Kit</label>
        <FormControlLabel control={<Switch checked={item.Kit} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />} label="" />
        {item.Kit == true && (
          <>
            <br /><b>Kit Details</b>
            <BulletedTextArea
              onChange={(value) => setItem({ ...item, ["KitDetails"]: value })}
              values={newarra}
              bulletChar={bulletChar}
            />
          </>

        )}</div>

        <div className="gear-qty-col">
        <TextField style={{width:'100%'}} required id="standard-basic" label="Quantity" value={item.Qty} name="Qty" onChange={onChange} variant="outlined" inputProps={{ inputMode: 'numeric' }} />
        </div>

        <div className="gear-weight-col">
        <NumberFormat style={{width:'100%'}} inputProps={{ inputMode: 'decimal',}} allowNegative={false} InputProps={{ endAdornment: <InputAdornment position="end">{getdata?.WeightPref == true || getdata?.WeightPref == undefined ? 'kg' : 'lb'}<IconButton size="small" style={{opacity: '0.5', marginLeft: '10px'}} onClick={handleClearClick}><ClearIcon /></IconButton></InputAdornment>, }} label="Weight" customInput={TextField} variant="outlined" name="Weight" value={item.Weight} thousandSeparator={true} decimalScale={3} isNumericString={true} fixedDecimalScale={true} displayType="input" onChange={onChange2Weight} />
        </div>

        <div className="gear-storage-col">
        <Select
          menuPortalTarget={document.body}
          menuPlacement="top"
          isSearchable={ false }
          fullWidth
          placeholder={"Storage"}
          options={getSorageL}
          name="StorageLocation"
          value={storageLocation}
          onChange={handleStorageLocationChange}
          getOptionLabel={x => x.label}
          getOptionValue={x => x.id}
          className="select indent"
          components={{
            ValueContainer: CustomValueContainer
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f1f1f1',
              primary: '#202020',
            },
          })}
          styles={{
            container: (provided, state) => ({
              ...provided,
              marginTop: 50,
              paddingLeft: "11px"
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              overflow: "visible",
              borderColor: "#fff !important",
              padding: "0 !important"
            }),
            control: (provided, state) => ({
              ...provided,
              borderColor: "#fff !important",
              boxShadow: "none"
            }),
            placeholder: (provided, state) => ({
              ...provided,
              position: "absolute",
              top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
              left: "-4px",
              background: "#fff",
              padding: "0 5px",
              transition: "top 0.5s, font-size 0.1s",
              color: "#A2A2A2",
              fontSize: (state.hasValue || state.selectProps.inputValue) && 13
            })
          }}
        />
 <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Customise storage locations in profile." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
        </div>

        <div className="gear-owner-col">
        <Select
          menuPortalTarget={document.body}
          menuPlacement="top"
          isSearchable={ false }
          fullWidth
          placeholder={"Owner"}
          options={getdataaa}
          name="Owner"
          value={val}
          className="select indent"
          onChange={handleChangeOwner}
          getOptionLabel={x => x.Name}
          getOptionValue={x => x.Name}
          components={{
            ValueContainer: CustomValueContainer
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: '#f1f1f1',
              primary: '#202020',
            },
          })}
          styles={{
            container: (provided, state) => ({
              ...provided,
              marginTop: 50,
              paddingLeft: "11px"
            }),
            valueContainer: (provided, state) => ({
              ...provided,
              overflow: "visible",
              borderColor: "#fff !important",
              padding: "0 !important"
            }),
            control: (provided, state) => ({
              ...provided,
              borderColor: "#fff !important",
              boxShadow: "none"
            }),
            placeholder: (provided, state) => ({
              ...provided,
              position: "absolute",
              top: state.hasValue || state.selectProps.inputValue ? -17 : "50%",
              left: "-4px",
              background: "#fff",
              padding: "0 5px",
              transition: "top 0.5s, font-size 0.1s",
              color: "#A2A2A2",
              fontSize: (state.hasValue || state.selectProps.inputValue) && 13
            })
          }}
        />
        <div className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '5px', position: 'absolute' }}>
                  <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Add travellers in profile and then allocate gear to a person or mark as a shared item." placement="top-start" arrow>
                    <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                  </Tooltip>
                </div>
        </div>

        { /* <FormDialogs /> */ }
        { /*
        <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <div style={{ width: '100%' }}>

              <div style={{marginBottom: '5px' }} className="travellerscontainer">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={"Warranty Expiry"}
                  inputFormat="dd/MM/yyyy"
                  disableCloseOnSelect={false}
                  disablePast={false}
                  value={item.WarrantyExpiry}
                  minDate={new Date()}
                  onChange={(newValue) => setItem({ ...item, ["WarrantyExpiry"]: format(newValue, 'MM/dd/yyyy') })}
                  renderInput={(params) => <TextField variant="outlined" {...params} helperText={null} />}
                />
              </LocalizationProvider><span className="mobilehide">&nbsp;&nbsp;</span>
              <input type="file" ref={refParam => inputRef = refParam} hidden={true} onChange={(event, index) => handleInputUpload(event)} name="proofreceipt" accept="image/*,.pdf" />
              {item.ProofReceipt ?
              <><Button className="btn-mobile-alt" variant="outlined" size="large" style={{marginTop:'10px',height:'56px'}}><a href={item.ProofReceipt} target="_blank">RECEIPT</a>&nbsp;&nbsp;
              &nbsp;<IconButton size="small" aria-label="download" onClick={() => {handleDownload(item.ProofReceipt, item.ProofReceiptName)}}><CloudDownloadIcon /></IconButton>
              &nbsp;<IconButton size="small" onClick={() => setItem({ ...item, ["ProofReceipt"]: null, ["ProofReceiptName"]: null }, setProofReceiptName("RECEIPT"))}><DeleteTwoToneIcon /></IconButton>
              </Button></>
              : <><Button className="btn-mobile-alt" size="large" onClick={() => inputRef.click()} variant="outlined" startIcon={<AttachFileIcon />} style={{marginTop:'10px',height:'56px'}}>
              {proofreceiptname}
              </Button></>}
              <span className="mobilehide">&nbsp;&nbsp;</span>
              <TextField id="standard-basic" label="Value" value={item.ProofValue} name="ProofValue" onChange={onChange2} inputProps={{ inputMode: 'numeric' }} variant="outlined" /><span className="mobilehide">&nbsp;&nbsp;</span>
              <input type="file" ref={refParam => inputRef2 = refParam} hidden={true} onChange={(event, index) => handleInputUpload2(event)} name="proofphoto" accept="image/*,.pdf" />
              {item.ProofPhoto ?
              <><Button className="btn-mobile-alt" variant="outlined" size="large" style={{marginTop:'10px',height:'56px'}}><a href={item.ProofPhoto} target="_blank">PHOTO</a>&nbsp;&nbsp;
              &nbsp;<IconButton size="small" aria-label="download" onClick={() => {handleDownload(item.ProofPhoto, item.ProofPhotoName)}}><CloudDownloadIcon /></IconButton>
              &nbsp;<IconButton size="small" onClick={() => setItem({ ...item, ["ProofPhoto"]: null, ["ProofPhotoName"]: null }, setProofPhotoName("PHOTO"))}><DeleteTwoToneIcon /></IconButton>
              </Button></>
              : <><Button className="btn-mobile-alt" size="large" onClick={() => inputRef2.click()} variant="outlined" startIcon={<AttachFileIcon />} style={{marginTop:'10px',height:'56px'}}>
              {proofphotoname}
              </Button></>}
              <span className="mobilehide">&nbsp;&nbsp;</span>
              <input type="file" ref={refParam => inputRef3 = refParam} hidden={true} onChange={(event, index) => handleInputUpload3(event)} name="proofmanual" accept="image/*,.pdf" />
              {item.ProofManual ?
              <><Button className="btn-mobile-alt" variant="outlined" size="large" style={{marginTop:'10px',height:'56px'}}><a href={item.ProofManual} target="_blank">MANUAL</a>&nbsp;&nbsp;
              &nbsp;<IconButton size="small" aria-label="download" onClick={() => {handleDownload(item.ProofManual, item.ProofManualName)}}><CloudDownloadIcon /></IconButton>
              &nbsp;<IconButton size="small" onClick={() => setItem({ ...item, ["ProofManual"]: null, ["ProofManualName"]: null }, setProofManualName("MANUAL"))}><DeleteTwoToneIcon /></IconButton>
              </Button></>
              : <><Button className="btn-mobile-alt" size="large" onClick={() => inputRef3.click()} variant="outlined" startIcon={<AttachFileIcon />} style={{marginTop:'10px',height:'56px'}}>
              {proofmanualname}
              </Button></>}
              <Tooltip className="travellerstooltipv2" leaveTouchDelay={3000} enterTouchDelay={50} title="These fields are purely a guide. To ensure policy compliance check requirements with the manufacturer or your insurer." placement="top-start" arrow>
                  <IconButton className={getdata?.TipsPref == true || getdata?.TipsPref == undefined ? 'tooltip' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
              </Tooltip>
              <label className="travellerslabel">Proof of Ownership for Warranty & Insurance</label>
              </div>
              </div></div> */ }
        <br />
        <Button
          disableElevation
          style={{opacity:'0', pointerEvents:'none'}}
          variant="outlined"
          color="default"
          size="large"
          type="submit"
          className="btn greybtn"
          onClick={() => {
            history.push('/gear/index')
            setAddItem(false)
            setEditing(false)
            // window.scrollTo(0, 0);
            var delay=0;//1*6 seconds
            setTimeout(function(){
              location.hash = item.id; //scrolls to element with given id
              document.getElementById(item.id).classList.add("just-edited");
            },delay); 
          }
          }
          startIcon={<FirstPageIcon />}
        >
          Back
      </Button>&nbsp;&nbsp;
      <Button
          style={{ display: 'none' }}
          disableElevation
          variant="outlined"
          color="default"
          className="submitauto"
          size="large"
          type="submit"
          onClick={onSubmit}
          startIcon={<SaveAltTwoToneIcon />}
        >
          Update Gear
      </Button>&nbsp;&nbsp;
      <Button
          disableElevation
          variant="outlined"
          color="secondary"
          size="large"
          type="submit"
          className="btn deletebtn redbtn"
          onClick={() => deleteItem(item.id)}
          startIcon={<DeleteTwoToneIcon />}
        >
          Delete
      </Button>
      </div>
    </Paper>
    </div>
  );
};
export default UpdateItem;